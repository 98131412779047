import http from "@/utils/http";
export async function get_quicbooks_details(){
    const req = (await http.get(`/quickbooks/authorize`));
    return req.data;   
}


export async function set_quickbooks_details(data){
    const req = (await http.get(`/quickbooks/callback`,{params:data}));
    return req.data;   
}

export async function get_quickbooks_account_details(){
    const req = (await http.get(`/quickbooks/account-info`));
    return req.data;  
}

export async function upload_to_quickbooks(data){
    const req = (await http.post(`/quickbooks/invoices`, data));
    return req.data;  
}

export async function remove_account(){
    const req = (await http.delete(`/quickbooks/remove-quickbooks`));
    return req.data;
}

export async function get_quickbooks_invoices(filters){
    const req = (await http.get(`/quickbooks/quickbookinvoices`, {params: filters}));
    return req.data;
}